import utilsFetch from './fetch';
import utilsStorage from './storage';
import coors from './coors';

const utils = {
  fetch: utilsFetch,
  storage: utilsStorage,
  coors,
  getHeaders: () => {
    return {
      Accept: 'application/json',
      // 'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    };
  },
};

export default utils;
