const utilsFetch = {
  process: r => {
    return new Promise(function (resolve, reject) {
      // https://developer.mozilla.org/es/docs/Web/API/Response/ok
      if (!r.ok) {
        try {
          r.json().then(reject);
          return;
        } catch (e) {
          reject(e);
          return;
        }
      }

      try {
        r.json().then(resolve);
      } catch (e) {
        reject(e);
      }
    });
  },
};

export default utilsFetch;
