import api from 'api';
import utils from 'utils';
import actions from 'actions';

const login = user => {
  return api.user.login({ email: user.email, password: user.password }).then(response => {
    window.user = response.data.user;
    window.user.isLogged = true;

    utils.storage.local.set('user', window.user);
    actions.event('login').call(response);

    return response.data.user;
  });
};

const logout = () => {
  window.user = {};

  utils.storage.local.set('user', window.user);
  actions.event('logout').call();
};

const getWithNotificationToken = () => {
  return api.user.getWithNotificationToken().then(x => x.data.users);
};

const updateSubscription = user => {
  return api.user.updateSubscription(user).then(x => x.data);
};

const actionsUser = {
  login,
  logout,
  getWithNotificationToken,
  updateSubscription,
};

export default actionsUser;
