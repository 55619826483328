import React, { useEffect } from 'react';
import actions from 'actions';
import './styles.scss';

const MainPhotoForm = ({ spot, onChange, onClose }) => {
  const editMainPhotoId = id => {
    if (spot.mainPhotoId === id) {
      onClose();
    } else {
      actions.spots.edit(spot, { mainPhotoId: id });
      onChange({ spotId: spot.id, mainPhotoId: id });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const unsplashCount = +document
        .querySelector('[data-unsplash-count]')
        .getAttribute('data-unsplash-count');
      const userCount = +document
        .querySelector('[data-user-count]')
        .getAttribute('data-user-count');

      const onlyOneUnsplash = unsplashCount === 1 && userCount === 0;
      const onlyOneUser = unsplashCount === 0 && userCount === 1;

      if (onlyOneUnsplash || onlyOneUser) {
        document.querySelector('.MainPhotoForm img').click();
      }
    }, 10);

    const _onKeyPress = e => {
      const actionButton = document.querySelector(`.MainPhotoForm [data-action-key="${e.key}"]`);
      // event.metaKey is CMD (CMD + 3, for example)
      if (actionButton && !e.metaKey && actionButton.click) {
        e.preventDefault();
        actionButton.click();
      }
    };

    document.addEventListener('keydown', _onKeyPress, false);

    return () => {
      document.removeEventListener('keydown', _onKeyPress, false);
    };
  }, []);

  let actionKey = 0;

  return (
    <div className="MainPhotoForm">
      <div data-spot-id={spot.id}>Spot Id: {spot.id}</div>

      <h1 data-unsplash-count={spot.photosHydrated.length}>
        Unsplash ({spot.photosHydrated.length})
      </h1>
      {spot.photosHydrated.map(x => {
        actionKey++;

        return (
          <img
            key={x.id}
            data-id={x.id}
            data-action-key={actionKey}
            src={x.urls.regular}
            alt={spot.name}
            onClick={() => editMainPhotoId(x.id)}
          />
        );
      })}

      <h1 data-user-count={spot.userPhotosHydrated.length}>
        User Photos ({spot.userPhotosHydrated.length})
      </h1>
      {spot.userPhotosHydrated.map(x => {
        actionKey++;

        return (
          <img
            key={x.id}
            data-id={x.id}
            data-action-key={actionKey}
            src={x.url}
            alt={spot.name}
            onClick={() => editMainPhotoId(x.id)}
          />
        );
      })}
    </div>
  );
};

export default MainPhotoForm;
