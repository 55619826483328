import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const collect = ({ seed, page }) => {
  return fetch(`${baseUrl}/mod/unsplash/collect/${seed}/${page}`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const apiUnsplash = {
  collect,
};

export default apiUnsplash;
