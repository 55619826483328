import React, { useState } from 'react';
import Button from 'UI/Button';
import config from 'config';
import './styles.scss';

const Actions = () => {
  const [lastResponse, setLastResponse] = useState({});
  const [lastResponseTime, setLastResponseTime] = useState(0);
  const [whoIsLoading, setWhoIsLoading] = useState({});

  const doAction = action => async () => {
    const baseUrl = config.api[window.location.hostname].baseUrl;
    const URLS = {
      generateSpotsV16: `${baseUrl}/mod/v16/generate-spots`,
      generateSpotsV17: `${baseUrl}/mod/v17/generate-spots`,
      generateSpotsV18: `${baseUrl}/mod/v18/generate-spots`,
      generateSpotsV19: `${baseUrl}/mod/v19/generate-spots`,
      generateLandingFiles: `${baseUrl}/mod/v15/generate-landing-files`,
      generateMarketingFiles: `${baseUrl}/mod/marketing/v1/generate-marketing-files`,
      clearStatsCache: `${baseUrl}/app/v1/cache/clear-stats`,
      clearPointsCache: `${baseUrl}/app/v1/cache/clear-points`,
      clearPointsSummaryCache: `${baseUrl}/app/v1/points/clear-cache`,
      createNewPointsSummaryCache: `${baseUrl}/app/v1/points/get-summary`,
    };

    const i = Date.now();
    setWhoIsLoading({ ...whoIsLoading, [action]: true });
    const r = await fetch(URLS[action]);
    const data = await r.json();
    setLastResponse(data);
    setLastResponseTime((Date.now() - i) / 1000);
    setWhoIsLoading({ ...whoIsLoading, [action]: false });
  };

  return (
    <div className="Actions content">
      <h1>Actions</h1>

      <div>Loading:</div>
      <div>{JSON.stringify(whoIsLoading)}</div>

      <div>
        <Button onClick={doAction('generateSpotsV17')}>Generate Spots V17</Button>
        <Button onClick={doAction('clearStatsCache')}>Clear Stats Cache</Button>
        <br />
        <br />
        <Button onClick={doAction('generateSpotsV18')}>Generate Spots V18</Button>
        <Button onClick={doAction('clearStatsCache')}>Clear Stats Cache</Button>
        <br />
        <br />
        <Button onClick={doAction('generateSpotsV19')}>Generate Spots V19</Button>
        <Button onClick={doAction('clearStatsCache')}>Clear Stats Cache</Button>
        <br />
        <br />
        <Button onClick={doAction('generateLandingFiles')}>Generate Landing Files</Button>
        <br />
        <br />
        <Button onClick={doAction('generateMarketingFiles')}>Generate Marketing Files</Button>
        <br />
        <br />
        <Button onClick={doAction('clearPointsCache')}>Clear Points</Button>
        <Button onClick={doAction('clearPointsSummaryCache')}>Clear Summary Points</Button>
        <Button onClick={doAction('createNewPointsSummaryCache')}>
          Create new Points Summary Cache
        </Button>
        <br />
        <br />
      </div>

      <div>{lastResponseTime} sec:</div>
      <div>{JSON.stringify(lastResponse)}</div>
    </div>
  );
};

export default Actions;
