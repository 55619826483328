import React, { Component } from 'react';
import moment from 'moment';
import actions from 'actions';
import utils from 'utils';
import LoadingPage from 'components/LoadingPage';
import './styles.scss';

class Collector extends Component {
  state = {
    callsInProgress: 0,
    remainingLimit: {
      limit: null,
      when: null,
    },
    isLoading: true,
    seeds: [],
    lastResponse: {},
  };

  constructor(props) {
    super(props);

    const limit = utils.storage.local.get('remainingLimit');

    if (limit) {
      this.state.remainingLimit = limit;
    }
  }

  componentDidMount() {
    this.getSeeds();
  }

  getSeeds = () => {
    this.setState({ isLoading: true });

    actions.seeds.getAll().then(seeds => {
      this.setState({ seeds, isLoading: false, lastResponse: seeds });
    });
  };

  _collectPage = async (seed, page) => {
    if (seed.totalPages > 0 && seed.lastCollectedPage >= seed.totalPages) {
      window.alert('Ta loco vo?');
      return;
    }

    try {
      const newPage = seed.lastCollectedPage + 1;

      this.setState({
        seeds: this.state.seeds.map(x =>
          x.id === seed.id ? { ...x, lastCollectedPage: newPage } : x
        ),
      });

      this.setState(state => ({ callsInProgress: state.callsInProgress + 1 }));
      const response = await actions.unsplash.collect({ seed: seed.name, page });
      this.setState(state => ({ callsInProgress: state.callsInProgress - 1 }));

      if (seed.totalPages !== response.totalPages) {
        this.setState(state => ({ callsInProgress: state.callsInProgress + 1 }));
        await this.changeTotalPages(seed, response.totalPages);
        this.setState(state => ({ callsInProgress: state.callsInProgress - 1 }));
      }

      this.setState(state => ({ callsInProgress: state.callsInProgress + 1 }));
      await actions.seeds.setLastCollectedPage(seed.id, newPage);
      this.setState(state => ({ callsInProgress: state.callsInProgress - 1 }));

      this.updateRemainingLimit(response.remainingLimit);

      this.setState({ lastResponse: response });
    } catch (e) {
      alert('Error. Please refresh the page. Error detail: ' + JSON.stringify(e));
    }
  };

  updateRemainingLimit = limit => {
    const remainingLimit = {
      limit: limit,
      when: Date.now(),
    };

    utils.storage.local.set('remainingLimit', remainingLimit);
    this.setState({ remainingLimit });
  };

  changeTotalPages = (seed, totalPages) => {
    return actions.seeds.setTotalPages(seed.id, totalPages).then(() => {
      const newSeeds = this.state.seeds.map(x =>
        x.id === seed.id ? { ...x, totalPages: totalPages } : x
      );

      this.setState({ seeds: newSeeds });
    });
  };

  _changeCurrentRun = (seed, run) => {
    if (!(seed.totalPages > 0 && seed.lastCollectedPage >= seed.totalPages)) {
      if (!window.confirm('You sure?')) {
        return;
      }
    }

    actions.seeds.setCurrentRun(seed.id, run).then(response => {
      const newSeeds = [...this.state.seeds].map(x =>
        x.id === seed.id
          ? { ...x, currentRun: x.currentRun + 1, lastRunAt: Date.now(), lastCollectedPage: 0 }
          : x
      );

      this.setState({
        lastResponse: response,
        seeds: newSeeds,
      });
    });
  };

  render() {
    return (
      <div className="Collector content">
        {this.state.isLoading && <LoadingPage />}

        <div className="remainingLimit">
          Remaining Limit:{' '}
          <span className={'limit ' + (this.state.remainingLimit.limit < 10 ? '--warning' : '')}>
            {this.state.remainingLimit.limit}
          </span>{' '}
          <span className="when">({moment(this.state.remainingLimit.when).fromNow()})</span>
        </div>

        <h2>
          Collect ({this.state.callsInProgress}) | New: ({this.state.lastResponse.newPhotosCount})
        </h2>

        <table className="table">
          <tbody>
            <tr>
              <th>Seed</th>
              <th colSpan="2">Pages</th>
              <th colSpan="2">Run</th>
              <th colSpan="2">Actions</th>
            </tr>

            <tr>
              <th className="table-text">Name</th>
              <th className="table-number">Last Collected</th>
              <th className="table-number">Total</th>
              <th className="table-number">Current</th>
              <th>Last At</th>
              <th>Collect</th>
              <th>Run</th>
            </tr>

            {[...this.state.seeds]
              .sort((a, b) => (a.lastRunAt < b.lastRunAt ? -1 : a.lastRunAt > b.lastRunAt ? 1 : 0))
              .map(seed => (
                <tr key={seed.id}>
                  <td className="table-text">{seed.name}</td>
                  <td className="table-number">{seed.lastCollectedPage}</td>
                  <td className="table-number">{!seed.totalPages ? 'Unknown' : seed.totalPages}</td>
                  <td className="table-number">{seed.currentRun}</td>
                  <td>{!seed.lastRunAt ? 'Never' : moment(seed.lastRunAt).fromNow()}</td>
                  <td
                    className="cta"
                    onClick={() => this._collectPage(seed, seed.lastCollectedPage + 1)}
                  >
                    Collect Page {seed.lastCollectedPage + 1}
                  </td>
                  <td
                    className={
                      'cta ' +
                      (seed.totalPages > 0 && seed.lastCollectedPage >= seed.totalPages
                        ? '--warning'
                        : '')
                    }
                    onClick={() => this._changeCurrentRun(seed, seed.currentRun + 1)}
                  >
                    Change to Run {seed.currentRun + 1}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Collector;
