import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const getAll = () => {
  return fetch(`${baseUrl}/mod/spots`, {
    method: 'GET',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const getAllLight = () => {
  return fetch(`${baseUrl}/mod/spots/get-all-light`, {
    method: 'GET',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const getHydratedById = spotId => {
  return fetch(`${baseUrl}/mod/spots/get-hydrated-by-id`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify({ spotId }),
  }).then(utils.fetch.process);
};

const create = spot => {
  return fetch(`${baseUrl}/mod/spots/create`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify({ spot }),
  }).then(utils.fetch.process);
};

const edit = (spot, newData) => {
  return fetch(`${baseUrl}/mod/spots/edit`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify({ spot, newData }),
  }).then(utils.fetch.process);
};

const assign = (spot, photo) => {
  return fetch(`${baseUrl}/mod/spots/assign`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify({ spot, photo }),
  }).then(utils.fetch.process);
};

const translateDescriptionById = (spotId) => {
  return fetch(`${baseUrl}/mod/spots/translate-description-by-id`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify({ spotId }),
  }).then(utils.fetch.process);
};

const apiSpots = {
  getAll,
  getAllLight,
  getHydratedById,
  create,
  edit,
  assign,
  translateDescriptionById
};

export default apiSpots;
