import React, { useEffect, useRef } from 'react';
import actions from 'actions';
import './styles.scss';

const TopPhotoForm = ({ spot, onChange, onClose }) => {
  const noButtonRef = useRef();

  const onEditIsTop = isTop => {
    if (spot.isTop === isTop) {
      onClose();
    } else {
      actions.spots.edit(spot, { isTop });
      onChange({ spotId: spot.id, isTop });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      document.querySelector('.TopPhotoForm [data-action-key="2"]').focus();
    }, 0);

    const _onKeyPress = e => {
      const actionButton = document.querySelector(`.TopPhotoForm [data-action-key="${e.key}"]`);
      // event.metaKey is CMD (CMD + 3, for example)
      if (actionButton && !e.metaKey && actionButton.click) {
        e.preventDefault();
        actionButton.click();
      }
    };

    document.addEventListener('keydown', _onKeyPress, false);

    return () => {
      document.removeEventListener('keydown', _onKeyPress, false);
    };
  }, []);

  return (
    <div className="TopPhotoForm">
      <div>Spot Id: {spot.id}</div>

      <div>
        <button data-action-key="1" className="approve" onClick={() => onEditIsTop(true)}>
          YES IT IS (1)
        </button>
        <button
          ref={noButtonRef}
          data-action-key="2"
          className="reject"
          onClick={() => onEditIsTop(false)}
        >
          NOP IT IS NOT (2)
        </button>
      </div>

      <h1 data-unsplash-count={spot.photosHydrated.length}>
        Unsplash ({spot.photosHydrated.length})
      </h1>
      {spot.photosHydrated.map(x => (
        <img key={x.id} data-id={x.id} src={x.urls.regular} alt={spot.name} />
      ))}

      <h1 data-user-count={spot.userPhotosHydrated.length}>
        User Photos ({spot.userPhotosHydrated.length})
      </h1>
      {spot.userPhotosHydrated.map(x => (
        <img key={x.id} data-id={x.id} src={x.url} alt={spot.name} />
      ))}
    </div>
  );
};

export default TopPhotoForm;
