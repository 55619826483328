import React, { Component } from 'react';
import actions from 'actions';
import './styles.scss';

const getRandom = array => {
  return array[Math.floor(Math.random() * array.length)];
};

const CURRENT_MARKETING = 'donation-and-follow';

class Marketing extends Component {
  state = {
    isLoading: false,
    unsplashUsersInApp: [],
    usersDone: [],
    usersToShow: [],
  };

  init = async () => {
    this.setState({ isLoading: true });

    const { users: usersDone } = await actions.marketing.getBy({
      where: { [CURRENT_MARKETING]: true },
    });

    const { users: unsplashUsersInApp } = await actions.marketing.getUnsplashUsers();
    unsplashUsersInApp.sort((a, b) => b.photos.length - a.photos.length);

    this.setState(
      {
        usersDone: usersDone.map(x => ({ ...x, username: x.unsplashId })),
        unsplashUsersInApp,
        isLoading: false,
      },
      this._filterUsers
    );
  };

  _onTextClick = e => {
    e.target.select();
    document.execCommand('copy');
    window.open(`https://unsplash.com/@${this.state.usersToShow[0].username}`);
  };

  _onReportedClick = async () => {
    const user = this.state.usersToShow[0];

    try {
      await actions.marketing.markAs({ unsplashId: user.username, markAs: CURRENT_MARKETING });
      this.setState({ usersDone: [...this.state.usersDone, user] }, this._filterUsers);
    } catch (error) {
      console.error('$$ Error:', error);
      window.alert('Error');
    }
  };

  _filterUsers = () => {
    const { unsplashUsersInApp, usersDone } = this.state;

    const usersToShow = unsplashUsersInApp.filter(
      x => !usersDone.some(y => y.username === x.username)
    );

    this.setState({ usersToShow });
  };

  getText = () => {
    const user = this.state.usersToShow[0];
    const space = ['\n\n', '\n \n', '\n  \n'];

    const hello = [
      `Hi ${user.name.split(' ')[0]}! We've used some of your photos (${
        user.photos.length
      }!) in our app.`,
      `Hello ${user.name.split(' ')[0]}! We've used some of your photos (${
        user.photos.length
      }!) in our app.`,
    ];

    const donation = [
      'I just sent you a small donation as a Thank You for sharing them on Unsplash! They are super important for us!',
    ];

    const these = ['These are some of the them:', 'Here are some of the them:'];

    const photos = user.photos.slice(0, 3);
    const yourPhotosArray = [
      photos.map(photo => `- https://unsplash.com/photos/${photo}`).join('\n'),
      photos.map(photo => `* https://unsplash.com/photos/${photo}`).join('\n'),
      photos.map(photo => `# https://unsplash.com/photos/${photo}`).join('\n'),
      photos.map(photo => `> https://unsplash.com/photos/${photo}`).join('\n'),
      photos.map((photo, i) => `${i + 1}- https://unsplash.com/photos/${photo}`).join('\n'),
    ];

    const link = [
      `We've added a link to your Unsplash account on each of them!`,
      `We've added a link to your Unsplash account on each of them ;)`,
    ];

    const short = [
      'NoFilter App is like a City Guide for Photographers.',
      'NoFilter App is like a City Guide for photographers',
    ];

    const long = [
      'We help photographers and travelers to discover the best photo spots nearby, and all around the world.',
      'We help travelers and photographers to discover the best photo spots nearby, and all around the world.',
      'We help travelers and photographers to discover the best photo locations nearby, and all around the world.',
    ];

    const check = [
      'You can read more in https://GetNoFilter.com (Free for Android & iOS)',
      'You can read more in https://GetNoFilter.com (Free for Android and iOS)',
    ];

    const helpUs = [
      "If there is any way you could help us to grow our community, we'll really appreciate it! (for example: talking to your friends about us).",
    ];
    const smallCompany = [
      'We are not a big company (just 2 members), so, any help is really welcome! Thanks in advance!',
    ];

    const insta = ["I'll look for you on Instagram! You found some nice spots!"];
    const ps = [
      'PS.: If you would like to promote your personal website/social networks, you can reply this email and I will give you details about how our users are doing it for free in NoFilter App',
    ];

    const me = ['Noel, NoFilter founder', 'Noel - NoFilter founder'];

    return (
      getRandom(hello) +
      getRandom(space) +
      getRandom(donation) +
      getRandom(space) +
      getRandom(these) +
      '\n' +
      getRandom(yourPhotosArray) +
      getRandom(space) +
      getRandom(link) +
      getRandom(space) +
      getRandom(short) +
      '\n' +
      getRandom(long) +
      getRandom(space) +
      getRandom(check) +
      getRandom(space) +
      getRandom(helpUs) +
      '\n' +
      getRandom(smallCompany) +
      getRandom(space) +
      getRandom(insta) +
      getRandom(space) +
      getRandom(ps) +
      getRandom(space) +
      'Bests!' +
      getRandom(space) +
      getRandom(me)
    );
  };

  render() {
    const { usersToShow } = this.state;

    return (
      <div className="Marketing">
        {usersToShow.length === 0 && <button onClick={this.init}>Init</button>}

        {usersToShow.length > 0 && (
          <textarea readOnly onClick={this._onTextClick} value={this.getText()} />
        )}

        <div className="users">
          {usersToShow.map(user => (
            <div key={user.username} className="user">
              {user.name} - {user.photos.length} -{' '}
              <span onClick={this._onReportedClick}>[MARK AS DONE]</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Marketing;
