import api from 'api';

const collect = ({ seed, page }) => {
  return api.unsplash.collect({ seed, page }).then(r => r.data);
};

const actionsUnsplash = {
  collect,
};

export default actionsUnsplash;
