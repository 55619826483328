import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const preCurationGetPendings = ({ quantity }) => {
  return fetch(`${baseUrl}/mod/photos/unsplash/precuration/pendings/${quantity}`, {
    method: 'GET',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const preCurationApprove = ({ id }) => {
  return fetch(`${baseUrl}/mod/photos/unsplash/precuration/approve/${id}`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const preCurationReject = ({ id }) => {
  return fetch(`${baseUrl}/mod/photos/unsplash/precuration/reject/${id}`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const curationGetPendings = ({ quantity }) => {
  return fetch(`${baseUrl}/mod/photos/unsplash/curation/pendings/${quantity}`, {
    method: 'GET',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const curationApprove = ({ id }) => {
  return fetch(`${baseUrl}/mod/photos/unsplash/curation/approve/${id}`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const curationReject = ({ id }) => {
  return fetch(`${baseUrl}/mod/photos/unsplash/curation/reject/${id}`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const getUnhydrated = ({ quantity }) => {
  return fetch(`${baseUrl}/mod/photos/unsplash/unhydrated/${quantity}`, {
    method: 'GET',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const hydrate = ({ id }) => {
  return fetch(`${baseUrl}/mod/photos/unsplash/hydrate/${id}`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const getHydratedsWithLocation = ({ limit, coors }) => {
  return fetch(
    `${baseUrl}/mod/photos/unsplash/getHydratedsWithLocation/${limit}/${coors.sw.lat}/${coors.sw.lng}/${coors.ne.lat}/${coors.ne.lng}`,
    {
      method: 'GET',
      headers: {
        Authorization: window.user.token,
        ...utils.getHeaders(),
      },
    }
  ).then(utils.fetch.process);
};

const getStats = () => {
  return fetch(`${baseUrl}/mod/photos/pending-count`, {
    method: 'GET',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const moderationReject = ({ id, cause }) => {
  return fetch(`${baseUrl}/mod/photos/unsplash/moderation/reject/${id}`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify({
      id,
      cause,
    }),
  }).then(utils.fetch.process);
};

const moderationApprove = ({ id }) => {
  return fetch(`${baseUrl}/mod/photos/unsplash/moderation/approve/${id}`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify({ id }),
  }).then(utils.fetch.process);
};

const detectiveGetReadys = () => {
  return fetch(`${baseUrl}/mod/photos/unsplash/ready-for-detective`, {
    method: 'GET',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const editById = (id, newData) => {
  return fetch(`${baseUrl}/mod/photos/edit-by-id`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify({ photoId: id, newData }),
  }).then(utils.fetch.process);
};

const actionPhotos = {
  preCuration: {
    getPendings: preCurationGetPendings,
    approve: preCurationApprove,
    reject: preCurationReject,
  },
  curation: {
    getPendings: curationGetPendings,
    approve: curationApprove,
    reject: curationReject,
  },
  moderation: {
    approve: moderationApprove,
    reject: moderationReject,
  },
  detective: {
    getReadys: detectiveGetReadys,
  },
  getUnhydrated,
  hydrate,
  getHydratedsWithLocation,
  getStats,
  editById,
};

export default actionPhotos;
