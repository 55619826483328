import React, { Component } from 'react';
import Curator from 'components/Curator';
import './styles.scss';

class Precuration extends Component {
  render() {
    return (
      <div className="Precuration content">
        <Curator action="preCuration" />
      </div>
    );
  }
}

export default Precuration;
