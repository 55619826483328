import api from 'api';

const getAll = () => {
  return api.favouriteRegions.getAll().then(x => x.data);
};

const favouriteRegions = {
  getAll,
};

export default favouriteRegions;
