import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const add = data => {
  return fetch(`${baseUrl}/mod/areas/add`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify({ data }),
  }).then(utils.fetch.process);
};

const getAll = () => {
  return fetch(`${baseUrl}/mod/areas/get-all`, {
    method: 'GET',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const editById = (id, newData) => {
  return fetch(`${baseUrl}/mod/areas/edit-by-id`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify({ id, newData }),
  }).then(utils.fetch.process);
};

const apiAreas = {
  add,
  getAll,
  editById,
};

export default apiAreas;
