import React, { Component } from 'react';
import Button from 'UI/Button';
import actions from 'actions';
import './styles.scss';

const PAGES = {
  INIT: 'init',
  GETTING_TOKENS: 'getting tokens',
  TOKENS_DONE: 'tokens done',
  TRANSLATING: 'translating',
  TRANSLATED: 'translated',
};

const SEND_TO = {
  ALL: 'all',
  ONLY_USERS: 'only-users',
  ONLY_NON_USERS: 'only-non-users',
  ONLY_FREE: 'only-free',
};

const removeRepeated = array => [...new Set(array)];

const PERFECT_HOUR = 18; // 18:00

class Notifications extends Component {
  state = {
    title: '',
    body: '',
    languages: [],
    knownTranslations: {},
    tokens: [],
    currentPage: PAGES.INIT,
    isSendInPerfectHour: true,
    sendTo: SEND_TO.ALL,
    offsetDays: '',
    countToSend: 0,
  };

  onTextChange =
    inputName =>
    ({ target }) => {
      this.setState({ [inputName]: target.value });
    };

  onGetTokens = async () => {
    if (!window.confirm('Are you fucking sure?')) {
      return;
    }

    this.setState({ currentPage: PAGES.GETTING_TOKENS });
    let tokens = await actions.notifications.getTokens();

    // tokens = tokens.filter(
    //   x =>
    //     x.userId === '481b3b7a-9b3d-4366-bbca-7856d5f98b17' || // me -3 timezone
    //     x.userId === '013aa019-f53f-4961-af3a-e32c17eef92c' || // a -8 timezone
    //     x.userId === '3dd4e01a-1ea8-4659-926e-ab60093fb142' // a +7 timezone
    // );

    // Remove an AnonymousToken, if there is an User with the same token.
    tokens = tokens.filter(token => {
      if (token.isUser) {
        return true;
      }

      const alreadyAnUserToken = !!tokens.find(
        x => x.isUser && x.expoNotiToken === token.expoNotiToken
      );

      if (alreadyAnUserToken) {
        return false;
      } else {
        return true;
      }
    });

    // Remove repeated tokens
    tokens = tokens.filter((token, i, self) => {
      return self.findIndex(x => x.expoNotiToken === token.expoNotiToken) === i;
    });

    const languages = removeRepeated(tokens.map(x => x.locale.split('-')[0]).filter(x => !!x));

    this.setState({
      tokens,
      languages,
      currentPage: PAGES.TOKENS_DONE,
      countToSend: tokens.length,
    });
  };

  onTranslate = async () => {
    const doIt = window.confirm('Are you fucking sure?');

    if (doIt) {
      const knownTranslations = {};

      this.setState({ currentPage: PAGES.TRANSLATING });

      for (const language of this.state.languages) {
        knownTranslations[language] = {};

        if (language === 'en') {
          knownTranslations[language].title = this.state.title;
          knownTranslations[language].body = this.state.body;
        } else {
          const dataTitle = await actions.translation.translate({
            q: this.state.title,
            source: 'en',
            target: language,
          });

          knownTranslations[language].title = dataTitle.translations[0].translatedText;

          if (this.state.body) {
            const dataBody = await actions.translation.translate({
              q: this.state.body,
              source: 'en',
              target: language,
            });

            knownTranslations[language].body = dataBody.translations[0].translatedText;
          }
        }
      }

      this.setState({ knownTranslations, currentPage: PAGES.TRANSLATED });
    }
  };

  onSchedule = () => {
    const { tokens, languages, sendTo, isSendInPerfectHour, knownTranslations, offsetDays } =
      this.state;

    if (!window.confirm('Are you fucking sure?')) {
      return;
    }

    if (!window.confirm('Are you SURE you want to send to: ' + sendTo)) {
      return;
    }

    let finalTokens = [];

    if (sendTo === SEND_TO.ALL) {
      finalTokens = tokens;
    } else if (sendTo === SEND_TO.ONLY_USERS) {
      finalTokens = tokens.filter(x => x.isUser);
    } else if (sendTo === SEND_TO.ONLY_NON_USERS) {
      finalTokens = tokens.filter(x => !x.isUser);
    } else if (sendTo === SEND_TO.ONLY_FREE) {
      finalTokens = tokens.filter(x => !x.subscriptionLevel);
    }

    actions.notifications.scheduleMasive({
      tokens: finalTokens,
      languages,
      knownTranslations,
      isSendInPerfectHour,
      offsetDays: offsetDays ? parseInt(offsetDays, 10) : 0,
    });
  };

  onProcessSchedules = () => {
    const doIt = window.confirm('Are you fucking sure?');

    if (doIt) {
      actions.notifications.process();
    }
  };

  onCheckErrors = async () => {
    const data = await actions.notifications.checkErrors();
    console.log('$$ data', data);
  };

  onProcessErrors = () => {
    const doIt = window.confirm('Are you sure?');

    if (doIt) {
      actions.notifications.processErrors();
    }
  };

  handleChangePerfectHour = e => {
    this.setState({ isSendInPerfectHour: e.target.checked });
  };

  handleChangeSendTo = sendTo => () => {
    const { tokens } = this.state;

    let finalTokens = [];

    if (sendTo === SEND_TO.ALL) {
      finalTokens = tokens;
    } else if (sendTo === SEND_TO.ONLY_USERS) {
      finalTokens = tokens.filter(x => x.isUser);
    } else if (sendTo === SEND_TO.ONLY_NON_USERS) {
      finalTokens = tokens.filter(x => !x.isUser);
    } else if (sendTo === SEND_TO.ONLY_FREE) {
      finalTokens = tokens.filter(x => x.isUser && !x.subscriptionLevel);
    }

    this.setState({ sendTo, countToSend: finalTokens.length });
  };

  render() {
    const { languages, countToSend } = this.state;

    return (
      <div className="Notifications content">
        <h1>Current Page: {this.state.currentPage}</h1>

        <div>
          <input
            type="checkbox"
            checked={this.state.isSendInPerfectHour}
            onChange={this.handleChangePerfectHour}
          />
          <span>
            Send {countToSend} at perfect hour ({PERFECT_HOUR}hs)
          </span>
        </div>

        <div>
          <input
            type="checkbox"
            checked={this.state.sendTo === SEND_TO.ALL}
            onChange={this.handleChangeSendTo(SEND_TO.ALL)}
          />
          <span>Send to ALL</span>
        </div>

        <div>
          <input
            type="checkbox"
            checked={this.state.sendTo === SEND_TO.ONLY_USERS}
            onChange={this.handleChangeSendTo(SEND_TO.ONLY_USERS)}
          />
          <span>Send to ONLY USERS</span>
        </div>

        <div>
          <input
            type="checkbox"
            checked={this.state.sendTo === SEND_TO.ONLY_NON_USERS}
            onChange={this.handleChangeSendTo(SEND_TO.ONLY_NON_USERS)}
          />
          <span>Send to ONLY "NON" USERS</span>
        </div>

        <div>
          <input
            type="checkbox"
            checked={this.state.sendTo === SEND_TO.ONLY_FREE}
            onChange={this.handleChangeSendTo(SEND_TO.ONLY_FREE)}
          />
          <span>Send to ONLY FREE USERS</span>
        </div>

        <input
          type="text"
          value={this.state.offsetDays}
          onChange={this.onTextChange('offsetDays')}
          placeholder="Write here the OFFSET OF DAYS (default: 0)"
        />

        <input
          type="text"
          value={this.state.title}
          onChange={this.onTextChange('title')}
          placeholder="Write here the TITLE"
        />

        <textarea
          value={this.state.body}
          onChange={this.onTextChange('body')}
          placeholder="Write here the BODY"
        />

        {languages.map(languageData => {
          const language = languageData.split('-')[0];

          if (!this.state.knownTranslations[language]) {
            return null;
          }

          return (
            <div key={language}>
              <span>
                {this.state.knownTranslations[language].title
                  ? `${language}: ${this.state.knownTranslations[language].title}`
                  : `=================== MISSING title FOR ${language} ===================`}
              </span>

              <br />

              {this.state.body && (
                <span>
                  {this.state.knownTranslations[language].body
                    ? `${language}: ${this.state.knownTranslations[language].body}`
                    : `=================== MISSING body FOR ${language} ===================`}
                </span>
              )}
            </div>
          );
        })}
        {this.state.title.length > 0 && (
          <div>
            <Button onClick={this.onGetTokens}>Get Tokens!</Button>
            <Button onClick={this.onTranslate}>Translate</Button>
            <Button onClick={this.onSchedule}>Schedule!</Button>
            <Button onClick={this.onProcessSchedules}>Process Schedules (send)! (good luck)</Button>
            <Button onClick={this.onCheckErrors}>Check errors!</Button>
            <Button onClick={this.onProcessErrors}>Process errors!</Button>
          </div>
        )}
      </div>
    );
  }
}

export default Notifications;
