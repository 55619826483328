import api from 'api';

const getAllUsers = () => {
  return api.searcher.getAllUsers().then(r => r.data);
};

const actionsSeeds = {
  getAllUsers,
};

export default actionsSeeds;
