import React, { Component } from 'react';
import actions from 'actions';
import Loading from 'components/Loading';
import LoadingPage from 'components/LoadingPage';
import Button from 'UI/Button';
import './styles.scss';

const PENDINGS_QUANTITY = 50;
const PROGRESS_LETTERS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

class Curator extends Component {
  state = {
    i: 0,
    pendings: [],
    isPreloaded: false,
    isLoading: true,
    showButtons: true,
    noMorePhotos: false,

    count: 0,
    startedAt: 0,
  };

  componentDidMount() {
    this.getPendings();
    document.addEventListener('keydown', this.onKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress);
  }

  onKeyPress = event => {
    if (event.key === 'ArrowLeft') {
      this._onReject();
      this.setState({ showButtons: false });
    } else if (event.key === 'ArrowRight') {
      this._onApprove();
      this.setState({ showButtons: false });
    } else if (event.key === 'Escape') {
      this.showPrev();
    }
  };

  showPrev = () => {
    if (this.state.i > 0) {
      this.setState({
        i: this.state.i - 1,
        count: this.state.count - 1,
      });
    }
  };

  getPendings = () => {
    actions.photos[this.props.action].getPendings(PENDINGS_QUANTITY).then(pendings => {
      this.setState({
        pendings,
        i: 0,
        isPreloaded: false,
        noMorePhotos: pendings.length === 0,
      });
    });
  };

  preloadPhotos = () => {
    this.state.pendings.forEach(photo => {
      new Image().src = photo.urls.regular;
    });
  };

  checkGetMorePhotos = () => {
    if (this.state.pendings.length - this.state.i === 0 && !this.state.isLoading) {
      this.setState({ isLoading: true });
      // TODO: Remove this 500.
      // Instead, check if the last promises were solved
      setTimeout(this.getPendings, 500);
    }
  };

  _onImageFinishLoad = () => {
    this.setState({ isLoading: false });

    if (!this.state.isPreloaded) {
      this.preloadPhotos();
      this.setState({ isPreloaded: true });
    }
  };

  _onApprove = () => {
    actions.photos[this.props.action].approve(this.state.pendings[this.state.i].id);
    this._nextPhoto();
  };

  _onReject = () => {
    actions.photos[this.props.action].reject(this.state.pendings[this.state.i].id);
    this._nextPhoto();
  };

  _nextPhoto = () => {
    const newState = {
      i: this.state.i + 1,
      count: this.state.count + 1,
    };

    if (this.state.startedAt === 0) {
      newState.startedAt = Date.now();
    }

    this.setState(newState, this.checkGetMorePhotos);
  };

  render() {
    const timeTake = (Date.now() - this.state.startedAt) / 1000 / 60;

    if (this.state.noMorePhotos) {
      return <h1>No more photos</h1>;
    }

    const photo = this.state.pendings[this.state.i] || { urls: {} };

    let progress = 0;

    if (photo.id) {
      progress = (PROGRESS_LETTERS.indexOf(photo.id[0]) / PROGRESS_LETTERS.length) * 100;
      progress += PROGRESS_LETTERS.indexOf(photo.id[1]) / PROGRESS_LETTERS.length;
    }

    return (
      <div className="Curator content">
        {this.state.isLoading && <LoadingPage />}

        {this.state.count > 0 && (
          <div className="data">
            {this.state.count} photos curated in {Math.round(timeTake)}min (
            {Math.round((60 * this.state.count) / timeTake)}/h). Progress: {progress.toFixed(2)}%
          </div>
        )}

        <div className={`current ` + (!this.state.showButtons ? '--with-no-buttons' : '')}>
          {this.state.pendings.length - this.state.i === 0 ? (
            <Loading />
          ) : (
            <img
              key={this.state.i}
              onLoad={this._onImageFinishLoad}
              src={photo.urls.regular}
              alt="Current"
              data-id={photo.id}
            />
          )}

          {this.state.showButtons && (
            <div className="buttons">
              <Button className="reject" onClick={this._onReject}>
                Reject
              </Button>
              <Button className="approve" onClick={this._onApprove}>
                Approve
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Curator;
