import api from 'api';

const getAllPendings = () => {
  return api.tips.getAllPendings().then(r => r.data);
};

const edit = data => {
  return api.tips.edit(data).then(r => r.data);
};

const actionsTips = {
  getAllPendings,
  edit,
};

export default actionsTips;
