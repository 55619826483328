import React, { Component } from 'react';
import Error from 'components/Error';
import Button from 'UI/Button';
import actions from 'actions';
import './styles.scss';

class Login extends Component {
  state = {
    email: '',
    password: '',

    error: '',
    isLoading: false,
  };

  _onEmailChanged = ({ target: { value } }) => {
    this.setState({ email: value });
  };

  _onPasswordChanged = ({ target: { value } }) => {
    this.setState({ password: value });
  };

  _onLogin = event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    actions.user
      .login({ email: this.state.email, password: this.state.password })
      .then(user => {
        window.location.href = `${user.redirect}`;
        window.scrollTo(0, 0);
      })
      .catch(error => {
        this.setState({ isLoading: false, error: error.message });
      });
  };

  render() {
    return (
      <div className="Login">
        <div className="left" />

        <div className="right">
          <form className="form" onSubmit={this._onLogin}>
            <h2>Welcome!</h2>

            <input
              autoFocus={true}
              type="text"
              placeholder="Email"
              value={this.state.email}
              onChange={this._onEmailChanged}
            />
            <input type="password" placeholder="Password" onChange={this._onPasswordChanged} />

            {this.state.error && <Error message={this.state.error} />}

            <Button type="submit" disabled={this.state.isLoading}>
              {this.state.isLoading ? 'Loading...' : 'Login'}
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
