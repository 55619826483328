import React from 'react';
import Loading from 'components/Loading';
import './styles.scss';

const LoadingPage = () => (
  <div className="LoadingPage flex flex-center">
    <Loading />
  </div>
);

export default LoadingPage;
