let events = {};

const event = (event) => {
  if (!Array.isArray(events[event])) {
    events[event] = [];
  }

  return {
    subscribe: (callback) => {
      const index = events[event].length;
      events[event][index] = callback;
      return index;
    },

    unsubscribe: (index) => {
      delete events[event][index];
    },

    call: (data) => {
      events[event].forEach((callback) => callback(data));
    }
  };
}

export default event;
