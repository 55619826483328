import api from 'api';

const getAll = () => {
  return api.seeds.getAll().then(r => r.data.seeds);
};

const add = seed => {
  return api.seeds.add(seed).then(r => r.data.seed);
};

const setLastCollectedPage = (seedId, lastPage) => {
  return api.seeds.setLastCollectedPage(seedId, lastPage).then(r => r.data);
};

const setTotalPages = (seedId, totalPages) => {
  return api.seeds.setTotalPages(seedId, totalPages).then(r => r.data);
};

const setCurrentRun = (seedId, currentRun) => {
  return api.seeds.setCurrentRun(seedId, currentRun).then(r => r.data);
};

const actionsSeeds = {
  getAll,
  add,
  setLastCollectedPage,
  setTotalPages,
  setCurrentRun,
};

export default actionsSeeds;
