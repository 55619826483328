import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const getAll = () => {
  return fetch(`${baseUrl}/mod/seeds`, {
    method: 'GET',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const add = seed => {
  return fetch(`${baseUrl}/mod/seeds`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify(seed),
  }).then(utils.fetch.process);
};

const setLastCollectedPage = (seedId, lastCollectedPage) => {
  return fetch(`${baseUrl}/mod/seeds/${seedId}/set/lastCollectedPage/${lastCollectedPage}`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const setTotalPages = (seedId, totalPages) => {
  return fetch(`${baseUrl}/mod/seeds/${seedId}/set/totalPages/${totalPages}`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const setCurrentRun = (seedId, currentRun) => {
  return fetch(`${baseUrl}/mod/seeds/${seedId}/set/currentRun/${currentRun}`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const apiSeeds = {
  getAll,
  add,
  setLastCollectedPage,
  setTotalPages,
  setCurrentRun,
};

export default apiSeeds;
