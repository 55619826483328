import React, { Component } from 'react';
import Curator from 'components/Curator';
import './styles.scss';

class Curation extends Component {
  render() {
    return (
      <div className="Curation content">
        <Curator action="curation" />
      </div>
    );
  }
}

export default Curation;
