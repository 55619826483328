import React from 'react';
import './styles.scss';

// # Photo Status
// * `0`: Collected
// * `1`: Approved - Curation
// * `2`: Rejected - Curation
// * `3`: Hydrated - With Location
// * `4`: Hydrated - Without Location
// * `5`: Approved - Moderation
// * `6`: Rejected - Moderation
// * `7`: Rejected - Drone
const STYLES_BY_STATUS = {
  UNSPLASH: {
    0: { backgroundColor: 'red' },
    3: { backgroundColor: 'yellow' },
    6: { backgroundColor: 'black' },
  },
  DETECTIVE: {
    99: { backgroundColor: 'green' },
  },
  USER: {
    0: { backgroundColor: '#00F' },
    2: { backgroundColor: '#006' },
  },
  AREA: {
    0: { backgroundColor: '#FFF' },
  },
  CITYTOUR: {
    0: { backgroundColor: 'blueviolet' },
  },
};

const ColorPoint = ({
  onClick = () => {},
  onHover = () => {},
  _status = 0,
  type,
  isSelected,
  isNativeGPS,
  name,
  from,
}) => (
  <div
    className={`ColorPoint ${type === 'USER' && (name || from) ? '--with-data' : ''} ${
      isNativeGPS ? '--native' : ''
    } ${isSelected ? '--active' : ''}`}
    onClick={onClick}
    onMouseOver={onHover}
    style={STYLES_BY_STATUS[type][_status]}
  ></div>
);

export default ColorPoint;
