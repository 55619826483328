import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const login = user => {
  return fetch(`${baseUrl}/mod/user/login`, {
    method: 'POST',
    headers: utils.getHeaders(),
    body: JSON.stringify(user),
  }).then(utils.fetch.process);
};

const updateSubscription = user => {
  return fetch(`${baseUrl}/mod/user/subscription/update?userId=${user.id}`, {
    method: 'GET',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const apiUsers = {
  login,
  updateSubscription,
};

export default apiUsers;
