import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleMap from 'google-map-react';
import ColorPoint from './Points/ColorPoint';
import SpotPoint from './Points/SpotPoint';
import CrosshairPoint from './Points/CrosshairPoint';
import config from 'config';
import utils from 'utils';
import './styles.scss';
import actions from 'actions';

class Map extends Component {
  state = {
    center: this.props.center,
    zoom: this.props.zoom,
    points: this.props.points,
    bounds: {
      ne: {
        lat: 0,
        lng: 0,
      },
      sw: {
        lat: 0,
        lng: 0,
      },
    },
  };

  UNSAFE_componentWillReceiveProps({ center, zoom, points }) {
    this.setState({ center, zoom, points });
  }

  shouldComponentUpdate(newProp) {
    const samePoints = JSON.stringify(newProp.points) === JSON.stringify(this.state.points);
    const sameCenter = JSON.stringify(newProp.center) === JSON.stringify(this.state.center);
    const sameZoom = newProp.zoom === this.state.zoom;
    const sameActions = newProp.actions === this.props.actions;

    return !samePoints || !sameCenter || !sameZoom || !sameActions;
  }

  _onMapChange = data => {
    this.setState(
      {
        zoom: data.zoom,
        bounds: data.bounds,
      },
      () => {
        if (typeof this.props.onChange === 'function') {
          this.props.onChange(data);
        }
      }
    );
  };

  onGoogleMapsReady = async ({ map, maps }) => {
    const { favouriteRegions } = await actions.favouriteRegions.getAll();

    favouriteRegions.forEach(favouriteRegion => {
      const paths = [
        { lat: favouriteRegion.ne.lat, lng: favouriteRegion.sw.lng },
        { lat: favouriteRegion.sw.lat, lng: favouriteRegion.sw.lng },
        { lat: favouriteRegion.sw.lat, lng: favouriteRegion.ne.lng },
        { lat: favouriteRegion.ne.lat, lng: favouriteRegion.ne.lng },
        { lat: favouriteRegion.ne.lat, lng: favouriteRegion.sw.lng },
      ];

      new maps.Polygon({
        paths,
        strokeColor: favouriteRegion.user.subscriptionLevel || 'blue',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#000',
        fillOpacity: 0,
        clickable: false,
      }).setMap(map);
    });
  };

  render() {
    const { onPointClick, onPointHover, onSpotClick } = this.props;
    const points = this.props.points.filter(
      x => x.isSelected || utils.coors.contained(x, this.state.bounds)
    );

    let subPoints = [];

    const currentPoint = points.find(x => x.isSelected);
    if (currentPoint && currentPoint.detective) {
      subPoints = currentPoint.detective.map(x => ({
        ...x,
        id: x.whoId,
        lat: x.position.latitude,
        lng: x.position.longitude,
        type: 'DETECTIVE',
        pointType: 'subPoint',
        _status: 99,
      }));
    }

    return (
      <div className="Map">
        <div className="actions">{this.props.actions}</div>

        <GoogleMap
          resetBoundsOnResize
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.onGoogleMapsReady}
          center={this.state.center}
          zoom={this.state.zoom}
          onChange={this._onMapChange}
          onClick={this.props.onClick}
          options={{
            tilt: 0,
            fullscreenControl: true,
            streetViewControl: true,
            mapTypeId: 'hybrid',
            styles: [
              {
                featureType: 'all',
                elementType: 'labels',
                stylers: [
                  {
                    visibility: '#on',
                  },
                ],
              },
            ],
          }}
          bootstrapURLKeys={{
            key: config.googleMaps.apiKey,
            libraries: 'places',
            language: 'en',
            region: 'en',
          }}
        >
          {subPoints.map(point => (
            <ColorPoint key={point.id} {...point} onClick={() => onPointClick(point)} />
          ))}

          {points.map(point => {
            if (point.pointType === 'photo') {
              return (
                <ColorPoint
                  key={point.id}
                  {...point}
                  onClick={() => onPointClick(point)}
                  onHover={() => onPointHover(point)}
                />
              );
            }

            if (point.pointType === 'userPhoto') {
              return (
                <ColorPoint
                  key={point.id}
                  {...point}
                  onClick={() => onPointClick(point)}
                  onHover={() => onPointHover(point)}
                />
              );
            }

            if (point.pointType === 'crosshair') {
              return <CrosshairPoint key="crosshair" {...point} />;
            }

            if (point.pointType === 'spot') {
              return <SpotPoint key={point.id} {...point} onClick={() => onSpotClick(point)} />;
            }

            if (point.pointType === 'area') {
              return <ColorPoint key={point.id} {...point} />;
            }

            if (point.pointType === 'citytour') {
              return <ColorPoint key={point.id} {...point} />;
            }

            return null;
          })}
        </GoogleMap>

        <div className="count">
          Visible points: {points.length} | Total points: {this.props.points.length}
        </div>
      </div>
    );
  }
}

// Current default center: Los Angeles
Map.defaultProps = {
  center: { lat: 34.0442, lng: -118.2488 },
  zoom: 1,
  points: [],
  actions: [],
  onChange: () => {},
  onClick: () => {},
  onPointClick: () => {},
  onPointHover: () => {},
};

Map.propTypes = {
  center: PropTypes.object,
  zoom: PropTypes.number,
  points: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.arrayOf(PropTypes.element),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onPointClick: PropTypes.func,
  onPointHover: PropTypes.func,
};

export default Map;
