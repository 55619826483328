import React, { Component, Fragment } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import utils from 'utils';
import Header from 'containers/Header';
import Translation from 'pages/Translation';
import Seeds from 'pages/Seeds';
import Precuration from 'pages/Precuration';
import Curation from 'pages/Curation';
import Collector from 'pages/Collector';
import Hydrate from 'pages/Hydrate';
import Moderation from 'pages/Moderation';
import Stats from 'pages/Stats';
import Marketing from 'pages/Marketing';
import Notifications from 'pages/Notifications';
import Tips from 'pages/Tips';
import Actions from 'pages/Actions';
import Areas from 'pages/Areas';
import Searcher from 'pages/Searcher';
// import Migration from 'pages/Migration';
import Login from 'pages/Login';
import 'styles/base.scss';

window.user = utils.storage.local.get('user') || {};

class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Fragment>
          <Header />

          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/translation" element={<Translation />} />
            <Route path="/seeds" element={<Seeds />} />
            <Route path="/pre-curation" element={<Precuration />} />
            <Route path="/curation" element={<Curation />} />
            <Route path="/collector" element={<Collector />} />
            <Route path="/hydrate" element={<Hydrate />} />
            <Route path="/moderation" element={<Moderation />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/marketing" element={<Marketing />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/tips" element={<Tips />} />
            <Route path="/actions" element={<Actions />} />
            <Route path="/areas" element={<Areas />} />
            <Route path="/searcher" element={<Searcher />} />
            {/* <Route path='/migration' element={<Migration />} /> */}
            <Route path="/" element={<Login />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    );
  }
}

export default App;
