import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import Button from 'UI/Button';
import LoadingPage from 'components/LoadingPage';
import actions from 'actions';
import './styles.scss';

const Tips = () => {
  const [tips, setTips] = useState([]);
  const [shouldGivePoints, setShouldGivePoints] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllSeeds();
  }, []);

  const getAllSeeds = async () => {
    setIsLoading(true);

    const { tips } = await actions.tips.getAllPendings();

    setTips(tips);
    setIsLoading(false);
  };

  const handleChange = field => e => {
    const newTips = cloneDeep(tips);
    newTips[0][field] = e.target.value;
    setTips(newTips);
  };

  const handleOnSave = () => {
    doEdit({ _status: 1 });
  };

  const handleOnRemove = () => {
    if (!window.confirm('Are you sure?')) {
      return;
    }

    doEdit({ _status: 2 });
  };

  const doEdit = async ({ _status }) => {
    if (typeof _status === 'undefined') {
      throw new Error('Missing _status');
    }

    const tip = tips[0];

    if (_status === 1 && !tip.sourceLanguage) {
      window.alert('Missing sourceLanguage');
      return;
    }

    setIsSaving(true);
    const newData = {
      _status,
      text: tip.text,
    };

    if (tip.sourceLanguage) {
      newData.sourceLanguage = tip.sourceLanguage;
    }

    await actions.tips.edit({
      tipId: tip.id,
      shouldGivePoints,
      newData,
    });

    setTips(tips.filter(x => x.id !== tip.id));
    setShouldGivePoints(true);
    setIsSaving(false);
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  const tip = tips[0];
  if (!tip) {
    return 'No tips to moderate';
  }

  return (
    <div className="Tips content">
      <h1>Tips</h1>

      <div>
        <h3>Spot ID</h3>
        <input readOnly type="text" value={tip.spot.id} />

        <h3>Probable Language</h3>
        <input readOnly type="text" value={tip.probableLanguage} />

        <h3>Source Language</h3>
        <input
          autoFocus
          type="text"
          value={tip.sourceLanguage || ''}
          onChange={handleChange('sourceLanguage')}
        />

        <h3>Text</h3>
        <textarea value={tip.text} onChange={handleChange('text')} />

        <h3>
          <input
            name="shouldGivePoints"
            type="checkbox"
            checked={shouldGivePoints}
            onChange={e => setShouldGivePoints(e.target.checked)}
          />
          Should Give Points
        </h3>

        <Button isDisabled={isSaving || !tip.sourceLanguage} onClick={handleOnSave}>
          {isSaving ? 'Saving...' : 'Save'}
        </Button>

        <Button isDisabled={isSaving} onClick={handleOnRemove}>
          Remove
        </Button>
      </div>
    </div>
  );
};

export default Tips;
