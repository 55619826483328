import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const markAs = params => {
  return fetch(`${baseUrl}/mod/marketing/markAs`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify(params),
  }).then(utils.fetch.process);
};

const getBy = params => {
  return fetch(`${baseUrl}/mod/marketing/getBy`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify(params),
  }).then(utils.fetch.process);
};

const getUnsplashUsers = () => {
  return fetch(
    `https://storage.googleapis.com/mari-a5cc7.appspot.com/mod/unsplash-users.json?rand=${Date.now()}`,
    {
      headers: {
        ...utils.getHeaders(),
      },
    }
  ).then(utils.fetch.process);
};

const apiMarketing = {
  markAs,
  getBy,
  getUnsplashUsers,
};

export default apiMarketing;
