/* eslint-disable no-script-url */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import './styles.scss';

const cleanString = (text = '') =>
  text
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replace('.', '')
    .replace('/', '-')
    .replace('(', '')
    .replace(')', '')
    .replace('’', '')
    .replace('´', '')
    .replace("'", '')
    .replace('"', '')
    .replace('#', '')
    .replace('<', '')
    .replace('>', '')
    .replace(/ /g, '-')
    .replace('-&-', '-and-')
    .replace('&', '-and-')
    .replace(',-', '-')
    .replace(';', '-')
    .replace(':', '-')
    .replace('--', '-')
    .replace('--', '-')
    .replace('--', '-')
    .replace('--', '-');

const getPossibleSlug = (name = '', from = '', country = '') => {
  const cleanedName = cleanString(name);
  const cleanedFrom = cleanString(from);
  const cleanedCountry = cleanString(country);

  let slug = cleanedName;

  if (cleanedFrom) {
    slug = `${slug}-from-${cleanedFrom}`;
  }

  if (cleanedCountry) {
    slug = `${slug}-in-${cleanedCountry}`;
  }

  return cleanString(slug);
};

const getCountry = async ({ lat, lng }) => {
  const r = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=en&result_type=country&key=${config.googleMaps.apiKey}`
  );
  const json = await r.json();
  return json.results[0].formatted_address.replace('The Bahamas', 'Bahamas');
};

class SpotForm extends Component {
  state = {
    // Real form
    name: this.props.name || '',
    from: this.props.from || '',
    slug: this.props.slug || '',
    country: this.props.country || '',

    // Spot found with the new slug
    spotWithSameSlug: null,

    isSearching: false,
  };

  searchForPoiInterval = null;
  async componentDidMount() {
    this.setNewSlug();

    this.searchForPoiInterval = setInterval(this.getDataFromPoI, 500);

    if (!this.state.country) {
      const country = await getCountry({
        lat: this.props.lat,
        lng: this.props.lng,
      });

      this.setState({ country }, this.setNewSlug);
    }
  }

  componentWillUnmount() {
    clearInterval(this.searchForPoiInterval);
  }

  UNSAFE_componentWillReceiveProps({ name, from, slug, country }) {
    this.setState({ name, from, slug, country });
  }

  getDataFromPoI = () => {
    const { name, from, country } = this.state;

    // If all done, return
    if (name && from && country) {
      clearInterval(this.searchForPoiInterval);
      return;
    }

    const titleElement = document.querySelector('.Map .gm-title');

    if (!titleElement || !titleElement.innerText) {
      return;
    }

    const title = titleElement.innerText.trim();

    // The title was already set in some of the values
    if (title === name || title === from) {
      return;
    }

    const newState = {};

    if (!name) {
      newState.name = title;
    } else if (!from) {
      newState.from = title;
    }

    this.setState(newState, () => {
      this.setNewSlug();
      document.querySelector('.Moderation button.approve').focus();
    });
  };

  _onInputChange = ({ target: { name, value } }) => {
    if (name === 'slug') {
      if (value) {
        this._checkIfSlugExists(value);
      } else {
        this.setState({ spotWithSameSlug: null });
      }
    }

    if (name === 'slug') {
      this.setState({ [name]: value }, this.onChange);
    } else {
      this.setState({ [name]: value }, this.setNewSlug);
    }
  };

  setNewSlug = () => {
    const { name, from, country } = this.state;

    const slug = getPossibleSlug(name, from, country);

    this.setState({ slug }, () => {
      this._checkIfSlugExists(slug);
      this.onChange();
    });
  };

  onChange = () => {
    const { name, from, slug, country } = this.state;
    this.props.onChange({ name, from, slug, country });
  };

  checkTimeout = null;
  _checkIfSlugExists = slug => {
    clearTimeout(this.checkTimeout);

    this.setState({ isSearching: true });

    this.checkTimeout = setTimeout(() => {
      const spotWithSameSlug = this.props.spots.find(x => x.slug === slug);
      this.setState({ spotWithSameSlug, isSearching: false });
    }, 200);
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  };

  render() {
    return (
      <div className="SpotForm">
        <form onSubmit={this.handleSubmit}>
          <input
            autoFocus={!this.state.name}
            type="text"
            className="name"
            placeholder="Spot name"
            name="name"
            value={this.state.name}
            onChange={this._onInputChange}
          />

          <input
            autoFocus={!!this.state.name && !this.state.from}
            type="text"
            className="from"
            placeholder="From"
            name="from"
            value={this.state.from}
            onChange={this._onInputChange}
          />

          <input
            type="text"
            className="country"
            placeholder="Country"
            name="country"
            value={this.state.country}
            onChange={this._onInputChange}
          />

          <input
            type="text"
            className="slug"
            placeholder="Slug"
            name="slug"
            value={this.state.slug}
            onChange={this._onInputChange}
          />

          <input className="submit" type="submit" />

          {this.state.isSearching && <span>Searching...</span>}
          {this.state.spotWithSameSlug && <h3>THERE IS AN SPOT WITH THIS SLUG!</h3>}
        </form>
      </div>
    );
  }
}

SpotForm.propTypes = {
  name: PropTypes.string,
  from: PropTypes.string,
  country: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SpotForm;
