import areas from './areas';
import cityTours from './cityTours';
import favouriteRegions from './favouriteRegions';
import user from './user';
import searcher from './searcher';
import seeds from './seeds';
import photos from './photos';
import userPhotos from './userPhotos';
import unsplash from './unsplash';
import spots from './spots';
import translation from './translation';
import marketing from './marketing';
// import migration from './migration';
import notifications from './notifications';
import tips from './tips';

const api = {
  // migration
  areas,
  cityTours,
  favouriteRegions,
  user,
  searcher,
  seeds,
  photos,
  userPhotos,
  unsplash,
  spots,
  translation,
  marketing,
  notifications,
  tips,
};

export default api;
