import api from 'api';

const getTokens = () => {
  return api.notifications.getTokens().then(x => x.data.tokens);
};

const scheduleMasive = params => {
  return api.notifications.scheduleMasive(params).then(x => x.data);
};

const process = () => {
  return api.notifications.process().then(x => x.data);
};

const checkErrors = () => {
  return api.notifications.checkErrors().then(x => x.data);
};

const processErrors = () => {
  return api.notifications.processErrors().then(x => x.data);
};

const actionsNotifications = {
  getTokens,
  scheduleMasive,
  checkErrors,
  process,
  processErrors,
};

export default actionsNotifications;
