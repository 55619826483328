import api from 'api';

const add = data => {
  return api.cityTours.add(data).then(r => r.data);
};

const actionsCityTours = {
  add,
};

export default actionsCityTours;
