const utilsCoors = {
  /**
   * This function checks if the `point` ({ lat, lng }) is
   * contained inside the area defined in
   * `bounds` ({ ne, nw, se, sw })
   * @returns Boolean
   */
  contained: (point, bounds) => {
    const eastBound = point.lng < bounds.ne.lng;
    const westBound = point.lng > bounds.sw.lng;
    let inLong;

    if (bounds.ne.lng < bounds.sw.lng) {
      inLong = eastBound || westBound;
    } else {
      inLong = eastBound && westBound;
    }

    if (!inLong) {
      return false;
    }

    // Is inLat?
    return point.lat > bounds.sw.lat && point.lat < bounds.ne.lat;
  },
};

export default utilsCoors;
