import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const getHydratedsWithLocation = ({ limit }) => {
  return fetch(`${baseUrl}/mod/userPhotos/getHydratedsWithLocation/${limit}`, {
    method: 'GET',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const reject = ({ id, cause, userId }) => {
  return fetch(`${baseUrl}/mod/userPhotos/reject/${id}`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify({ id, cause, userId }),
  }).then(utils.fetch.process);
};

const approve = ({ id, spotId, userId }) => {
  return fetch(`${baseUrl}/mod/userPhotos/approve/${id}`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify({ id, spotId, userId }),
  }).then(utils.fetch.process);
};

const apiUserPhotos = {
  getHydratedsWithLocation,
  approve,
  reject,
};

export default apiUserPhotos;
