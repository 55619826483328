import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Slider = ({ photos }) => {
  const [index, setIndex] = useState(0);

  return (
    <div className="Slider">
      {index > 0 && <div className="arrow arrow-left" onClick={() => setIndex(index - 1)}></div>}

      {photos.length - index - 1 > 0 && (
        <div className="arrow arrow-right" onClick={() => setIndex(index + 1)}></div>
      )}

      <img src={photos[index]} alt="Current visible" />
    </div>
  );
};

Slider.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Slider;
