import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const getAll = () => {
  return fetch(`${baseUrl}/mod/favouriteRegions/get-all`, {
    method: 'GET',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const apiUsers = {
  getAll,
};

export default apiUsers;
