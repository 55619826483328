import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const getAllUsers = () => {
  return fetch(`${baseUrl}/mod/v1/searcher/get-all-users`, {
    method: 'GET',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
  }).then(utils.fetch.process);
};

const apiSeeds = {
  getAllUsers,
};

export default apiSeeds;
