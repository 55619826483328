import React, { Component } from 'react';
import actions from 'actions';
import LoadingPage from 'components/LoadingPage';
import './styles.scss';

class Stats extends Component {
  state = {
    isLoading: true,
    pendings: 0,
  };

  componentDidMount() {
    this.getStats();
  }

  getStats = () => {
    this.setState({ isLoading: true });

    actions.photos.getStats().then(pendings => {
      this.setState({ pendings, isLoading: false });
    });
  };

  render() {
    return (
      <div className="Stats">
        {this.state.isLoading && <LoadingPage />}

        <div className="content">
          <h4>{this.state.pendings} photos estan pendientes de ser curadas.</h4>
          <h4>{this.state.pendings / 1500} horas pendientes (a 1500/h)</h4>
          <h4>{this.state.pendings / 2000} horas pendientes (a 2000/h)</h4>
          <h4>{this.state.pendings / 2500} horas pendientes (a 2500/h)</h4>
          <h4>{this.state.pendings / 3000} horas pendientes (a 3000/h)</h4>
          <h4>{this.state.pendings / 3500} horas pendientes (a 3500/h)</h4>
          <h4>{this.state.pendings / 4000} horas pendientes (a 4000/h)</h4>
        </div>
      </div>
    );
  }
}

export default Stats;
