import api from 'api';

const add = data => {
  return api.areas.add(data).then(r => r.data);
};

const getAll = () => {
  return api.areas.getAll().then(r => r.data);
};

const editById = (id, newData) => {
  return api.areas.editById(id, newData).then(r => r.data);
};

const actionsAreas = {
  add,
  getAll,
  editById,
};

export default actionsAreas;
