import React, { Component } from 'react';
import moment from 'moment';
import actions from 'actions';
import Button from 'UI/Button';
import LoadingPage from 'components/LoadingPage';
import './styles.scss';

class Seeds extends Component {
  state = {
    seed: '',
    seeds: [],
    isLoading: false,
  };

  componentDidMount() {
    this.getAllSeeds();
  }

  getAllSeeds = () => {
    this.setState({ isLoading: true });

    actions.seeds
      .getAll()
      .then(seeds => {
        this.setState({ seeds, isLoading: false });
      })
      .catch(error => {
        // TODO: Implement Error
      });
  };

  _onChange = ({ target: { value } }) => {
    this.setState({ seed: value.toLowerCase() });
  };

  _onAdd = event => {
    event.preventDefault();
    const name = this.state.seed.trim();

    const alreadyExists = this.state.seeds.filter(x => x.name === name).length > 0;

    if (alreadyExists) {
      // TODO: Add some error message here
      return;
    }

    this.setState({ isLoading: true });

    actions.seeds
      .add({ name })
      .then(() => {
        this.setState({ seed: '', isLoading: false });
        this.getAllSeeds();
      })
      .catch(() => {
        // TODO: Implement
      });
  };

  filter = seeds => {
    const term = this.state.seed;
    return seeds.filter(x => x.name.indexOf(term) !== -1);
  };

  render() {
    return (
      <div className="Seeds content">
        {this.state.isLoading && <LoadingPage />}

        <div className="newSeed">
          <form onSubmit={this._onAdd}>
            <input
              type="text"
              value={this.state.seed}
              placeholder="New seed"
              onChange={this._onChange}
              onKeyPress={this._onKeyPress}
            />

            <Button onClick={this._onAdd}>Add</Button>
          </form>
        </div>

        <div className="left">
          <h3>Last added</h3>

          {this.filter([...this.state.seeds].sort((a, b) => a.createdAt < b.createdAt)).map(
            seed => (
              <div key={seed.id}>
                {seed.name}
                <span className="time"> ({moment(seed.createdAt).fromNow()})</span>
              </div>
            )
          )}
        </div>

        <div className="right">
          <h3>All seeds</h3>

          {this.filter(
            [...this.state.seeds].sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
          ).map(seed => (
            <div key={seed.id}>{seed.name}</div>
          ))}
        </div>
      </div>
    );
  }
}

export default Seeds;
