import OpenAI from 'openai';

const OPENAI_API_KEY = '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';

const openai = new OpenAI({
  apiKey: OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

const getTextV4 = async (system, question) => {
  const r = await openai.chat.completions.create({
    model: 'gpt-4o',
    messages: [
      {
        role: 'system',
        content: system,
      },
      {
        role: 'user',
        content: question,
      },
    ],
    temperature: 1,
    max_tokens: 1500,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
  });

  return r.choices[0].message.content.trim();
};

const actionsOpenai = {
  getTextV4,
};

export default actionsOpenai;
