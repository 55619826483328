import api from 'api';

const preCurationGetPendings = quantity => {
  return api.photos.preCuration.getPendings({ quantity }).then(r => r.data.photos);
};

const preCurationApprove = id => {
  return api.photos.preCuration.approve({ id }).then(r => r.data);
};

const preCurationReject = id => {
  return api.photos.preCuration.reject({ id }).then(r => r.data);
};

const curationGetPendings = quantity => {
  return api.photos.curation.getPendings({ quantity }).then(r => r.data.photos);
};

const curationApprove = id => {
  return api.photos.curation.approve({ id }).then(r => r.data);
};

const curationReject = id => {
  return api.photos.curation.reject({ id }).then(r => r.data);
};

const getUnhydrated = quantity => {
  return api.photos.getUnhydrated({ quantity }).then(r => r.data.photos);
};

const hydrate = ({ id }) => {
  return api.photos.hydrate({ id }).then(r => r.data);
};

const getHydratedsWithLocation = ({ limit, coors }) => {
  return api.photos.getHydratedsWithLocation({ limit, coors }).then(r => r.data.photos);
};

const getStats = () => {
  return api.photos.getStats().then(r => r.data);
};

const moderationReject = ({ id, cause }) => {
  return api.photos.moderation.reject({ id, cause }).then(r => r.data);
};

const moderationApprove = ({ id }) => {
  return api.photos.moderation.approve({ id }).then(r => r.data);
};

const detectiveGetReadys = () => {
  return api.photos.detective.getReadys().then(r => r.data);
};

const editById = (id, newData) => {
  return api.photos.editById(id, newData).then(r => r.data);
};

const actionsPhotos = {
  preCuration: {
    getPendings: preCurationGetPendings,
    approve: preCurationApprove,
    reject: preCurationReject,
  },
  curation: {
    getPendings: curationGetPendings,
    approve: curationApprove,
    reject: curationReject,
  },
  moderation: {
    approve: moderationApprove,
    reject: moderationReject,
  },
  detective: {
    getReadys: detectiveGetReadys,
  },
  getUnhydrated,
  hydrate,
  getHydratedsWithLocation,
  getStats,
  editById,
};

export default actionsPhotos;
