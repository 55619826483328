const BASE_URL = 'https://translation.googleapis.com';
const KEY = 'AIzaSyAIu_7NfZ10WpwAkd0VDK9H76r9VxBQmA4';

const translate = ({ q, source, target }) => {
  return fetch(
    `${BASE_URL}/language/translate/v2/?q=${q}&source=${source}&target=${target}&format=text&key=${KEY}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      },
    }
  ).then(r => r.json());
};

const apiTranslation = {
  translate,
};

export default apiTranslation;
