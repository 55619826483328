import React, { Component } from 'react';
import cloneDeep from 'lodash.clonedeep';
import LoadingPage from 'components/LoadingPage';
import Map from 'components/Map';
import actions from 'actions';
import './styles.scss';

class Areas extends Component {
  state = {
    name: '',
    slug: '',
    gpss: '',
    areas: [],
    //
    lastActiveGPSSIndex: null,
    //
    center: undefined,
    zoom: undefined,
  };

  reset = callback => {
    this.setState(
      {
        name: '',
        slug: '',
        gpss: '',
        areas: [],
      },
      callback
    );
  };

  getData = async () => {
    const { areas } = await actions.areas.getAll();
    this.setState({ areas });
  };

  componentDidMount() {
    this.getData();
  }

  _onMapClick = ({ lat, lng }) => {
    const { areas, gpss, lastActiveGPSSIndex } = this.state;

    if (lastActiveGPSSIndex !== null) {
      if (lastActiveGPSSIndex === -1) {
        this.setState({ gpss: gpss ? `${gpss};${lat},${lng}` : `${lat},${lng}` });
      } else {
        const newAreas = cloneDeep(areas);
        newAreas[lastActiveGPSSIndex].gpss = `${newAreas[lastActiveGPSSIndex].gpss};${lat},${lng}`;
        this.setState({ areas: newAreas });
      }
    }
  };

  handleChange = (i, field, value) => {
    const { areas } = this.state;
    const newAreas = cloneDeep(areas);
    newAreas[i][field] = value;

    this.setState({ areas: newAreas });
  };

  handleCreateNew = async () => {
    const { name, slug, gpss } = this.state;

    try {
      await actions.areas.add({
        name,
        slug,
        gpss,
      });

      this.reset(this.getData);
    } catch (error) {
      window.alert('error', error);
    }
  };

  _onMapChange = ({ zoom, center }) => {
    this.setState({ zoom, center });
  };

  render() {
    const { name, slug, gpss, areas, lastActiveGPSSIndex } = this.state;

    const points = areas
      .map(x => {
        const gpss = x.gpss.split(';').map(x => x.split(','));

        return gpss.map(gps => ({
          id: `${gps[0]}-${gps[1]}`,
          pointType: 'area',
          type: 'AREA',
          lat: Number(gps[0]),
          lng: Number(gps[1]),
        }));
      })
      .flat();

    if (gpss) {
      points.push(
        ...gpss
          .split(';')
          .map(x => x.split(','))
          .filter(Boolean)
      );
    }

    return (
      <div className="Areas">
        {this.state.isLoading && <LoadingPage />}

        <div className="left">
          <h1>Areas</h1>

          <div className="Areas__block">
            <div className="Areas__block-name">
              <div>
                <h4>Name</h4>
                <input
                  type="text"
                  value={name}
                  placeholder="Name"
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </div>
              <div>
                <h4>Slug</h4>
                <input
                  type="text"
                  value={slug}
                  placeholder="Slug"
                  onChange={e => this.setState({ slug: e.target.value })}
                />
              </div>
            </div>
            <div>
              <h4>GPSs</h4>
              <input
                type="text"
                className={`${lastActiveGPSSIndex === -1 ? '--active' : ''}`}
                value={gpss}
                placeholder="Gpss"
                onClick={() => this.setState({ lastActiveGPSSIndex: -1 })}
                onChange={e => this.setState({ gpss: e.target.value })}
              />
            </div>

            <button className="reject" onClick={() => this.setState({ lastActiveGPSSIndex: null })}>
              Stop
            </button>

            <button className="approve" onClick={this.handleCreateNew}>
              Create
            </button>
          </div>

          {areas.map((area, i) => (
            <div key={area.id} className="Areas__block">
              <div className="Areas__block-name">
                <div>
                  <h4>Name</h4>
                  <input
                    type="text"
                    value={area.name}
                    placeholder="Name"
                    onChange={e => this.handleChange(i, 'name', e.target.value)}
                  />
                </div>
                <div>
                  <h4>Slug</h4>
                  <input
                    type="text"
                    value={area.slug}
                    placeholder="Slug"
                    onChange={e => this.handleChange(i, 'slug', e.target.value)}
                  />
                </div>
              </div>
              <div>
                <h4>GPSs</h4>
                <input
                  type="text"
                  className={`${lastActiveGPSSIndex === i ? '--active' : ''}`}
                  value={area.gpss}
                  placeholder="Gpss"
                  onClick={() => this.setState({ lastActiveGPSSIndex: i })}
                  onChange={e => this.handleChange(i, 'gpss', e.target.value)}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="right">
          <div className="Areas__map">
            <Map
              points={points}
              onClick={this._onMapClick}
              center={this.state.center}
              zoom={this.state.zoom}
              onChange={this._onMapChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Areas;
