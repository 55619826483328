import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Conditional from 'components/Conditional';
import actions from 'actions';
import './styles.scss';

const WHERE_TO_SHOW = [
  '/translation',
  '/seeds',
  '/pre-curation',
  '/curation',
  '/collector',
  '/hydrate',
  '/moderation',
  '/stats',
  '/marketing',
  '/notifications',
  '/tips',
  '/actions',
  '/areas',
  '/searcher',
];

const LINKS = [
  { to: '/seeds', children: 'Seeds' },
  { to: '/collector', children: 'Collect' },
  { to: '/pre-curation', children: 'Pre-Curation' },
  { to: '/curation', children: 'Curation' },
  { to: '/hydrate', children: 'Hydrate' },
  { to: '/moderation', children: 'Mod' },
  { to: '/tips', children: 'Tips' },
  { to: '/translation', children: 'Transl' },
  { to: '/notifications', children: 'Noti' },
  { to: '/marketing', children: 'Mkt' },
  { to: '/actions', children: 'Actions' },
  { to: '/areas', children: 'Areas' },
  { to: '/searcher', children: 'Searcher' },
  // { to: '/stats', children: 'Stats' },
];

const Header = () => {
  const location = useLocation();
  const isLogged = !!window.user.isLogged;

  useEffect(() => {
    const subscriptionLogin = actions.event('login').subscribe(() => {
      window.location.reload();
    });

    const subscriptionLogout = actions.event('logout').subscribe(() => {
      window.location.reload();
    });

    return () => {
      actions.event('login').unsubscribe(subscriptionLogin);
      actions.event('logout').unsubscribe(subscriptionLogout);
    };
  }, []);

  const handleLogout = () => {
    actions.user.logout();
  };

  if (WHERE_TO_SHOW.indexOf(location.pathname) === -1) {
    return null;
  }

  const user = window.user;

  return (
    <div className="Header">
      <div className="content">
        <nav className="menu">
          <Conditional if={!isLogged}>
            <Link to="/login" className="item">
              Log in
            </Link>
          </Conditional>

          <Conditional if={isLogged}>
            {LINKS.map(props => {
              if (!user || !user.permissions) {
                return null;
              }

              const permissions = user.permissions;

              if (permissions.indexOf('all') !== -1 || permissions.indexOf(props.to) !== -1) {
                return (
                  <Link
                    key={props.children}
                    {...props}
                    className={`item ${location.pathname === props.to && '--active'}`}
                  />
                );
              }

              return null;
            })}

            <Link onClick={handleLogout} to="/" className="item">
              Log out
            </Link>
          </Conditional>
        </nav>
      </div>
    </div>
  );
};

export default Header;
