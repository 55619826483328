import api from 'api';

const translate = data => {
  return api.translation.translate(data).then(r => r.data);
};

const actionsTranslation = {
  translate,
};

export default actionsTranslation;
