import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const getTokens = () => {
  return fetch(`${baseUrl}/mod/pushNotifications/getTokens`, {
    method: 'GET',
    headers: {
      ...utils.getHeaders(),
      Authorization: window.user.token,
    },
  }).then(utils.fetch.process);
};

const scheduleMasive = params => {
  return fetch(`${baseUrl}/mod/pushNotifications/scheduleMasive`, {
    method: 'POST',
    headers: {
      ...utils.getHeaders(),
      Authorization: window.user.token,
    },
    body: JSON.stringify(params),
  }).then(utils.fetch.process);
};

const process = params => {
  return fetch(`${baseUrl}/mod/pushNotifications/processSchedules`, {
    method: 'POST',
    headers: {
      ...utils.getHeaders(),
      Authorization: window.user.token,
    },
    body: JSON.stringify(params),
  }).then(utils.fetch.process);
};

const checkErrors = () => {
  return fetch(`${baseUrl}/mod/pushNotifications/checkErrors`, {
    method: 'GET',
    headers: {
      ...utils.getHeaders(),
      Authorization: window.user.token,
    },
  }).then(utils.fetch.process);
};

const processErrors = () => {
  return fetch(`${baseUrl}/mod/pushNotifications/processErrors`, {
    method: 'GET',
    headers: {
      ...utils.getHeaders(),
      Authorization: window.user.token,
    },
  }).then(utils.fetch.process);
};

const apiNotifications = {
  getTokens,
  scheduleMasive,
  checkErrors,
  process,
  processErrors,
};

export default apiNotifications;
