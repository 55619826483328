/**
 * @description This component is going to show the children elements only if
 * the prop "if" is true.
 * @example
 * <Conditional if={this.state.showData}>
 *   <div>foo bar</div>
 * </Conditional>
 * @link if you want to read more about why this is necessary, just read this
 * article: https://medium.com/@BrodaNoel/conditional-rendering-in-react-and-jsx-the-solution-7c80beba1e36
 * @param {Object} props { if: Boolean }
 */
const Conditional = (props) => {
  return (
    !!props.if && props.children
  );
};

export default Conditional;
