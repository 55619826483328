import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const add = data => {
  return fetch(`${baseUrl}/mod/cityTours/add`, {
    method: 'POST',
    headers: {
      Authorization: window.user.token,
      ...utils.getHeaders(),
    },
    body: JSON.stringify(data),
  }).then(utils.fetch.process);
};

const apiAreas = {
  add,
};

export default apiAreas;
