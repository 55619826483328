import React, { Component } from 'react';
import moment from 'moment';
import actions from 'actions';
import utils from 'utils';
import LoadingPage from 'components/LoadingPage';
import './styles.scss';

class Hydrate extends Component {
  state = {
    callsInProgress: 0,
    remainingLimit: {
      limit: null,
      when: null,
    },
    isLoading: true,
    seeds: [],
    unhydrated: [],
    lastResponse: null,
    hydrateInProgress: 0,
  };

  constructor(props) {
    super(props);

    const limit = utils.storage.local.get('remainingLimit');

    if (limit) {
      this.state.remainingLimit = limit;
    }
  }

  componentDidMount() {
    this.getUnhydrated();
  }

  getUnhydrated = () => {
    this.setState({ isLoading: true });

    actions.photos.getUnhydrated(300).then(unhydrated => {
      this.setState({ unhydrated, isLoading: false, lastResponse: unhydrated });
    });
  };

  updateRemainingLimit = limit => {
    const remainingLimit = {
      limit: limit,
      when: Date.now(),
    };

    utils.storage.local.set('remainingLimit', remainingLimit);
    this.setState({ remainingLimit });
  };

  _hydrate = photo => {
    this.setState(
      state => {
        return {
          unhydrated: state.unhydrated.filter(x => x.id !== photo.id),
          hydrateInProgress: state.hydrateInProgress + 1,
        };
      },
      () => {
        actions.photos.hydrate(photo).then(response => {
          this.updateRemainingLimit(response.remainingLimit);

          this.setState(
            state => {
              return {
                hydrateInProgress: state.hydrateInProgress - 1,
              };
            },
            () => {
              if (this.state.unhydrated.length === 0 && this.state.hydrateInProgress === 0) {
                this.getUnhydrated();
              }
            }
          );
        });
      }
    );
  };

  onHydrateAllClick = () => {
    let delay = 0;
    this.state.unhydrated.forEach(photo => {
      delay = delay + Math.random() * 200;

      setTimeout(() => {
        this._hydrate(photo);
      }, delay);
    });
  };

  render() {
    return (
      <div className="Hydrate content">
        {this.state.isLoading && <LoadingPage />}

        <div className="remainingLimit">
          Remaining Limit:{' '}
          <span className={'limit ' + (this.state.remainingLimit.limit < 10 ? '--warning' : '')}>
            {this.state.remainingLimit.limit}
          </span>{' '}
          <span className="when">({moment(this.state.remainingLimit.when).fromNow()})</span>
        </div>

        <h2>Hydrate ({this.state.hydrateInProgress})</h2>

        <button onClick={this.onHydrateAllClick}>
          Hydrate all ({this.state.unhydrated.length})
        </button>

        <table className="table">
          <tbody>
            <tr>
              <th>Photo</th>
              <th>Actions</th>
            </tr>

            <tr>
              <th className="table-text">Preview</th>
              <th>Hydrate</th>
            </tr>

            {[...this.state.unhydrated].map(photo => (
              <tr key={photo.id}>
                <td>
                  <img data-id={photo.id} src={photo.urls.small} alt="Unhydrated" />
                </td>
                <td className="cta" onClick={() => this._hydrate(photo)}>
                  Hydrate
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="console">
          <pre>{JSON.stringify(this.state.lastResponse, null, 4)}</pre>
        </div>
      </div>
    );
  }
}

export default Hydrate;
